import { ExperienceData } from './types'

export const experienceData: ExperienceData[] = [
    {
        title: 'Website Content Coordinatorship',
        company: 'Altinbas University',
        location: 'Istanbul, Türkiye',
        dates: 'Oct 2023 - Sep 2024',
        description: '',        
        logoSrc: 'https://i0.wp.com/www.akademikportal.com/wp-content/uploads/2017/12/alt%C4%B1nba%C5%9F.jpg?fit=400%2C400&ssl=1'    
    },
    {
        title: 'UNICEF Face-to-Face Program',
        company: 'UNICEF',
        location: 'Istanbul, Türkiye',
        dates: 'Nov 2022 - Dec 2022',
        description: '',        
        logoSrc: 'https://logowik.com/content/uploads/images/930_unicef.jpg'    
    }
]
