import { FC } from 'react'
import {
    Container,
    Heading,
    Stack,
    Image,
    VStack,
    Text,
    useColorModeValue,
    Flex,
    Center,
} from '@chakra-ui/react'
import { Element } from 'react-scroll'
import Fade from 'react-reveal/Fade'
import headshot from '../../media/pp.png'

const About: FC = () => {
    return (
        <Element name='aboutme'>
            <Container maxW={'5xl'} p={6.75} mt={[20, 10]}>
                <VStack spacing={8}>
                    <Fade>
                        <Heading as='h2'>About me</Heading>
                    </Fade>
                    <Stack
                        position='relative'
                        alignItems='center'
                        direction={['column', 'row']}
                        textAlign={{ base: 'left', sm: 'justify', lg: 'left' }}
                    >
                        <Image
                            borderRadius='full'
                            boxSize='150px'
                            src={headshot}
                            border={`3px solid ${useColorModeValue('#1E2428', '#ECEFF4')}`}
                        />
                        <VStack spacing={4} p={[10, 4]} pl={[10, 50]}>
                            <Fade bottom cascade duration={500}>
                                <Flex>
                                    <Center p={4}>👋🏼</Center>
                                    <Text>
                                        Hi, I'm Efe and I'm a third year
                                        student at the{' '}
                                        <b>Altinbas University</b> with a
                                        passion for <b>Management Information Systems</b>{' '}
                                        and all things technology!
                                    </Text>
                                </Flex>
                            </Fade>
                            <Fade bottom cascade duration={500}>
                                <Flex>
                                    <Center p={4}>🎓</Center>
                                    <Text>
                                    In 2021, I embarked on my coding journey, venturing into the programming realm with <b>Python</b>. Evolving my skills, I delved into databases and web development, mastering HTML and CSS. This journey ignited a passion, steering me toward the exciting domain of <b>full-stack</b> development.
                                    </Text>
                                </Flex>
                            </Fade>
                            <Fade bottom cascade duration={500}>
                                <Flex>
                                    <Center p={4}>👨🏻‍💻</Center>
                                    <Text>
                                        Currently, I{' '}m actively refining my full-stack skills, concurrently immersing myself in the realms of <b><a className="font-medium transition hover:text-primary" href="https://react.dev/" target="_blank">React</a></b> for dynamic user interfaces, <b><a className="font-medium transition hover:text-primary" href="https://www.mongodb.com" target="_blank">MongoDB</a></b> for efficient database management, and <b><a className="font-medium transition hover:text-primary" href="https://huggingface.co" target="_blank">Hugging Face</a></b> for cutting-edge natural language processing in AI. 
                                    </Text>
                                </Flex>
                            </Fade>
                            <Fade bottom cascade duration={500}>
                                <Flex>
                                    <Center p={4}>🚀</Center>
                                    <Text>
                                        My projects seamlessly integrate these technologies, showcasing a harmonious blend of coding and AI. Explore some of my work in full-stack development and artificial intelligence on my <b><a className="font-medium transition hover:text-primary" href="https://github.com/Veucci" target="_blank">Github</a></b>.

                                    </Text>
                                </Flex>
                            </Fade>
                            
                            {/* 
                            <Fade bottom cascade duration={500}>
                                <Flex>
                                    <Center p={4}>🧠</Center>
                                    <Text>
                                        In addition to Software Engineering, I
                                        am also interested in the confluence of
                                        artificial intelligence and cognitive
                                        science, as well as photography! Check
                                        out my&nbsp;
                                        <Link
                                            href='https://photo.jasonfyw.com'
                                            isExternal
                                            fontWeight={'semibold'}
                                            borderBottom={
                                                '2px solid rgb(154, 228, 217)'
                                            }
                                            transition={'0.2s'}
                                            _hover={{
                                                textDecor: 'none',
                                                opacity: 0.9,
                                            }}
                                        >
                                            photography portfolio to learn more
                                            about my creative work.
                                        </Link>
                                        .
                                    </Text>
                                </Flex>
                            </Fade>
                            */}
                        </VStack>
                    </Stack>
                </VStack>
            </Container>
        </Element>
    )
}

export default About
