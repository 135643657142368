import {
    MongoBadge,
    PythonBadge,
} from '../components/Projects/TechBadges'
import { FiGithub, FiLink } from 'react-icons/fi'
import { ProjectT } from './types'

export const projectData: ProjectT[] = [
    {
        name: 'Turkish Lyrics Genre Classification Model',
        id: 'turkishmodel',
        type: 'AI Model',
        tagline: 'Turkish Lyrics Genre Classification Model',
        tags: [<PythonBadge />],
        imageSrc: '/turkish-lyric-to-genre.webp',
        description:
            "The model was trained using the BERT language model on my song lyrics dataset to predict the genre of a given song based on its lyrics.",
        links: [
            {
                link: 'https://github.com/Veucci/turkish-lyric-to-genre',
                icon: <FiGithub />,
            },
        ],
        headerLink: 'https://github.com/Veucci/turkish-lyric-to-genre',
    },
    {
        name: 'Lyrics Genre Classification Model',
        id: 'lyricsmodel',
        type: 'AI Model',
        tagline: 'Lyrics Genre Classification Model',
        tags: [<PythonBadge />],
        imageSrc: '/lyrics-to-genre.webp',
        description:
            "The model was trained using the BERT language model on my song lyrics dataset to predict the genre of a given song based on its lyrics.",
        links: [
            {
                link: 'https://github.com/Veucci/lyrics-to-genre',
                icon: <FiGithub />,
            },
        ],
        headerLink: 'https://github.com/Veucci/lyric-to-genre',
    },
    {
        name: 'Turkish Retr0 TV',
        id: 'tv',
        type: 'Web app',
        tagline: 'A retro tv webapp for nostalgia.',
        tags: [<PythonBadge />, <MongoBadge />],
        imageSrc: '/tv.webp',
        description:
            'A retro tv webapp for nostalgia.',
        links: [
            {
                link: 'https://retrotv.efeozkan.tech',
                icon: <FiLink/>,
            },
        ],
        headerLink: 'https://retrotv.efeozkan.tech',
    },
    {
        name: 'Patates Sevenler Dashboard',
        id: 'dash',
        type: 'Web app',
        tagline:
            'A dashboard for discord server user management. Also my starter project',
        tags: [<PythonBadge />, <MongoBadge />],
        imageSrc: '/dash.webp',
        description:
            'A dashboard for discord server user management. Also my starter project',
        links: [
            {
                link: 'https://github.com/Veucci/PatatesSevenler',
                icon: <FiGithub />,
            },
            {
                link: 'https://dashboard-html.vercel.app',
                icon: <FiLink />,
            },
        ],
        headerLink: 'https://jasonfyw.com/fourier-series/',
    },
]
